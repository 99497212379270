import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Icon, { GlyphNames } from '../../../components/icon';
import AdminPage from '../../../components/adminPage';
import { useAdminUser } from '../../../hooks/adminUser';
import PageLoading from '../../../components/pageLoading';
import ErrorHeading from '../../../components/errorHeading';
import { getResourceText } from '../../../lib/language';
import { useApi } from '../../../hooks/api';
import { formatDateWithTime } from '../../../lib/date';

const AdminUsers = () => {
  useAdminUser();
  const [users, setUsers] = useState();
  const [error, setError] = useState(false);

  const { admin: { getUsers } } = useApi();

  useEffect(() => {
    const getData = async () => {
      const { status, json: users } = await getUsers();

      if (status === 200) {
        setUsers([...users].sort(({ organisation: a = '', userId: userA }, { organisation: b = '', userId: userB }) => {
          const orgCompare = a.localeCompare(b);
          if (orgCompare === 0) {
            return userA.localeCompare(userB);
          }
          return orgCompare;
        }));
      } else {
        setError(true);
      }
    };
    getData().catch(() => setError(true));
  }, [getUsers]);

  return (
    <AdminPage withBack={false}>
      <div className="admin-users-container">
        <div className="admin-users-container__main-heading-container">
          <h1 className="admin-users-container__main-heading">{getResourceText('users')}</h1>
          <Link to="/admin/users/add" className="btn admin-btn admin-users-main-heading-container__add-new" aria-label="Add new user" title="Add new user">
            <Icon glyphName={GlyphNames.plus}/>
          </Link>
        </div>
        {!error && !users ? <PageLoading/> : null}
        {error ? <ErrorHeading text="Error getting users"/> : null}
        {users && !users.length ? <h2 className="admin-users-container__sub-heading">{getResourceText('noUsersFound')}</h2> : null}
        {!error && users ? (
          <div className="admin-users-list-container">
            {users.length ? (
              <table className="admin-users-table">
                <thead>
                <tr>
                  <th>{getResourceText('usernameTableHeading')}</th>
                  <th>{getResourceText('emailTableHeading')}</th>
                  <th>{getResourceText('organisationTableHeading')}</th>
                  <th>{getResourceText('userLastLoginTableHeading')}</th>
                  <th>{getResourceText('adminUserTableHeading')}</th>
                  <th>{getResourceText('isActiveTableHeading')}</th>
                  <th>{getResourceText('actionsTableHeading')}</th>
                </tr>
                </thead>
                <tbody>
                {users.map(({ userId, email, isSystemAdmin, isActive, lastLoginDate, organisation }) =>
                  <tr key={userId}>
                    <td>{userId}</td>
                    <td>{email}</td>
                    <td className="admin-users-table__center">{organisation}</td>
                    <td className="admin-users-table__center">{lastLoginDate ? formatDateWithTime(lastLoginDate) : 'N/A'}</td>
                    <td className="admin-users-table__center">{isSystemAdmin ? <Icon glyphName={GlyphNames.tick}/> : null}</td>
                    <td className="admin-users-table__center">{isActive ? <Icon glyphName={GlyphNames.tick}/> : null}</td>
                    <td><Link to={`/admin/users/${userId}/edit`}>Edit</Link></td>
                  </tr>)}
                </tbody>
              </table>
            ) : null}
          </div>
        ) : null}
      </div>
    </AdminPage>
  );
};

export default AdminUsers;